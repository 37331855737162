<template>
  <div class="burger-menu">
    <ul>
      <li v-for="(item, i) in lists" :key="i" @click="redirect(item.path)" class="text-lg medium gray-900">
        {{ $t(item.title) }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const emits = defineEmits(["closeBurger"]);

const lists = ref([
  {
    title: "Главная",
    path: '/'
  },
  {
    title: "catalog",
    path: '/catalog'
  },
  {
    title: "company",
    path: '/about'
  },
  {
    title: "blog",
    path: '/blog'
  },
  {
    title: "partnership",
    path: '/partners'
  },
  {
    title: "clients",
    path: '/clients'
  },
  {
    title: "school",
    path: '/school'
  }
])

const redirect = (event) => {
  if(event){
    router.push(event);
    emits("closeBurger");
  }
}

</script>

<style>

</style>