import { useI18n } from 'vue-i18n';

export function getLC(data, key, locale = null) {
  const { locale: currentLocale } = useI18n();
  const language = locale || currentLocale.value;

  if (data.translations && data.translations[language] && data.translations[language][key]) {
    return data.translations[language][key];
  }

  return data[key] || '';
}

export function formatNumber(value) {
  return new Intl.NumberFormat('ru-RU').format(value);
}