<template>
  <div v-if="route.name && route.name.includes('404')">
    <router-view />
  </div>
  <div v-else>
    <the-header v-if="(route.name && route.name !== 'Main') && (route.name && route.name !== 'School')" />
    <main class="app__height">
      <router-view />
    </main>
    <the-footer :class="{'footer__mobile' : route.name === 'Main'}"  />
    <navbar />
  </div>
</template>

<script setup>
import TheHeader from "@/components/global/TheHeader.vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import TheFooter from "./components/global/TheFooter.vue";
import Navbar from "./components/global/Navbar.vue"

const route = useRoute();
const store = useStore();

const isLoading = ref(true);

onMounted(async() => {
  await store.dispatch("global/GetCountriesList").then(() => {
    isLoading.value = false;
  })
})

console.log()
</script>

<style>
.app__height {
  min-height: 60vh;
}
</style>
