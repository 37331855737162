import axios from 'axios';
import store from '@/store'; 

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL + 'api/',
  headers: {
    'Access-Control-Allow-Origin': "*"
  }
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("authToken");
  if (token) {
      config.headers.Authorization = `Bearer ${token}`;
  }

  const currentLocale = localStorage.getItem('lang') || 'ru';
  config.headers['Accept-Language'] = currentLocale;

  return config;
});

axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem("authToken");
      store.dispatch('main/handleAuthModal', true);
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;