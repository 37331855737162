// Импортируйте axiosInstance, если он вам нужен
import router from 'vue-router';
import axiosInstance from '../../helpers/instance';

const state = {
  profile: null,
  transactions: null,
  promocodes: null,
  orders: null,
  address:[]
};

const getters = {};

const actions = {
  async getProfile({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/cabinet/profile/index');

      if (response.data) {
        commit('setProfile', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          localStorage.removeItem('authToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async myOrders({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/cabinet/profile/order/index');

      if (response.data) {
        commit('setOrder', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      if(error && error.response && error.response){
        if(error.response.status == 403 || error.response.status == 401){
          localStorage.removeItem('authToken');
          router.push('/')
        }
      }
      throw error;
    }
  },
  async getAddress({ commit }) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/cabinet/profile/addresses/index');

      if (response.data) {
        commit('setAddress', response.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async addAddress({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/cabinet/profile/addresses/store', body);

      await dispatch('getProfile');
      await dispatch('getAddress');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async editAddress({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/cabinet/profile/addresses/update', body);

      await dispatch('getProfile');
      await dispatch('getAddress');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async removeAddress({ commit, dispatch }, id) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/cabinet/profile/addresses/delete', id);

      await dispatch('getProfile');
      await dispatch('getAddress');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async changeFullName({ commit, dispatch }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/cabinet/profile/change_first_and_last_names', postData);

      await dispatch('getProfile');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getPaymentLinkToRechargeBalance({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/payment/get_payment_link_to_recharge', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async createPromocode({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/cabinet/profile/promocodes/create', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async deletePromocode({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/cabinet/profile/promocodes/delete', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async rechargeBalance({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('/payment/recharge_balance', body);

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async getTransactions({ commit, dispatch }, body) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.get('/payment/get_transactions', body);
      console.log(response);
      if (response.data && response.data.data) {
        commit('setTransactions', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async changePhoneNumber({ commit, dispatch }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cabinet/profile/send_change_phone_number_sms', postData);

      await dispatch('getProfile');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async confirmChangePhoneNumber({ commit, dispatch }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cabinet/profile/confirm_change_phone_number_sms', postData);

      await dispatch('getProfile');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async changePhoneNumberForPassword({ commit, dispatch }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cabinet/profile/send_change_password_sms', postData);

      await dispatch('getProfile');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async confirmPhoneNumberForPassword({ commit, dispatch }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cabinet/profile/confirm_change_password_sms', postData);

      await dispatch('getProfile');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
  async changePassword({ commit, dispatch }, postData) {
    try {
      axiosInstance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'ru';

      const response = await axiosInstance.post('cabinet/profile/change_password', postData);

      await dispatch('getProfile');

      return response.data;
    } catch (error) {
      console.log('Ошибка:', error);
      throw error;
    }
  },
};

const mutations = {
  setProfile(state, data) {
    state.profile = data;
    state.promocodes = data.data.promocodes;
  },
  setTransactions(state, data) {
    state.transactions = data;
  },
  setAddress(state, data) {
    state.address = data.data;
  },
  setOrder(state, data) {
    state.orders = data.data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
