<template>
  <div class="drawer drawer-right basket" :class="{ 'show-modal': animateModal }">
    <div class="drawer__inner">
      <main-loader v-if="isLoading" class="main__loader-mini" />
      <div v-else class="drawer__wrap">
        <div class="basket__header">
          <div class="basket__header-item">
            <p class="text-xl semibold gray-900">
              {{ basket ? $t("basket") : "" }}
            </p>
            <span
              class="text-sm gray-500 regular flex-1"
              v-if="basket && basket.products && basket.products.length > 0"
            >
              {{ basket.products.length }} {{ $t("pieces") }}
            </span>
            <span
              class="gray-blue-700 text-sm semibold cursor-pointer"
              @click="clearBasket()"
              v-if="basket && basket.length > 0"
            >
              {{ $t("deleteAll") }}
            </span>
          </div>
          <span class="drawer__close">
            <img @click="close()" src="@/assets/images/x-close.svg" alt="" />
          </span>
        </div>
        <div
          class="basket__content"
          :class="{
            'basket__empty-state':
              !basket || (basket && basket.products && basket.products.length == 0),
          }"
        >
          <div
            class="cards"
            v-if="basket && basket.products && basket.products.length > 0"
          >
            <div class="card" v-for="(item, i) in basket.products" :key="i">
              <img
                :src="item.product.photo_uris[0]"
                alt=""
                v-if="item.product.photo_uris && item.product.photo_uris.length > 0"
              />
              <img v-else src="@/assets/images/empty-bg.png" alt="" />
              <div class="flex-1">
                <!-- <span class="text-sm gray-900 regular">
                  {{ item.description }}
                </span> -->
                <p class="text-md semibold gray-900">
                  {{ item.product.title ?? "" }}
                </p>
                <div class="align-center" v-if="item.product.discount_price">
                  <span class="text-md semibold gray-900">
                    {{ formatNumber(item.product.discount_price) }} ₸
                  </span>
                  <span class="text-sm semibold gray-400 text-through">
                    {{ formatNumber(item.product.price) ?? "" }} ₸
                  </span>
                </div>
                <div class="align-center" v-else>
                  <span class="text-md semibold gray-900">
                    {{ formatNumber(item.product.price) ?? "" }} ₸
                  </span>
                </div>
              </div>
              <div>
                <div class="crash" @click="deleteProduct(item)">
                  <img src="@/assets/images/remove-icon.svg" alt="" />
                </div>
                <div class="quantity">
                  <span @click="reduceProduct(item)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M2.5 6H9.5"
                        stroke="#344054"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                  <b>
                    {{ item.quantity }}
                  </b>
                  <span @click="pluseQuantity(item)">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M6 2.5V9.5M2.5 6H9.5"
                        stroke="#344054"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="text-field"
            v-if="basket && basket.products && basket.products.length > 0"
          >
            <input
              v-model="promocode"
              type="text"
              placeholder="Введите промокод"
              :disabled="activePromocode"
              :readonly="activePromocode"
            />
            <button
              style="white-space: nowrap"
              class="button-border text-md seimbold"
              v-if="
                activePromocode && basket && basket.promocode && basket.promocode.user
              "
            >
              {{ basket.promocode.user.last_name }}
              {{ basket.promocode.user.first_name[0] }}.
            </button>
            <button
              v-else
              class="button-border text-md seimbold"
              :disabled="activePromocode"
              @click="usePromocode"
            >
              Применить
            </button>
          </div>
          <ul
            class="total"
            v-if="basket && basket.price && basket.products && basket.products.length > 0"
          >
            <li>
              <p class="text-md semibold gray-900">Сумма заказа</p>
            </li>
            <li v-if="basket.price && basket.price.total_usual_price">
              <p class="text-md regular gray-900">Стоимость продукции</p>
              <span class="text-md regular gray-900">
                {{ formatNumber(basket?.price?.total_usual_price) ?? 0 }} ₸
              </span>
            </li>
            <li v-if="basket.price && basket.price.promocode_value_from_price">
              <p class="text-md regular gray-900">Промокод</p>
              <span class="text-md regular gray-900">
                - {{ formatNumber(basket?.price?.promocode_value_from_price) ?? 0 }} ₸
              </span>
            </li>
            <li>
              <p
                class="text-md regular gray-900"
                v-if="basket.price && basket.price.total_price"
              >
                {{ $t('total') }}
              </p>
              <span class="text-xl bold gray-900">
                {{ formatNumber(basket?.price?.total_price) ?? 0 }} ₸</span
              >
            </li>
          </ul>
          <div class="basket__empty" v-else>
            <h1 class="text-lg semibold gray-900">{{ $t("cartIsEmpty") }}</h1>
            <p class="gray-600 text-md regular">{{ $t("browseCatalog") }}</p>
            <button class="button text-md semibold" @click="redirect('/catalog')">
              {{ $t("goToCatalog") }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="!isLoading && basket && basket.products && basket.products.length > 0"
        class="drawer__actions"
      >
        <button class="button text-lg semibold" @click="redirect('/order')">
          {{ $t('checkout') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, onMounted, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

import { formatNumber } from '@/helpers/helpers';

defineProps();

const router = useRouter();

const emits = defineEmits(["closeModal"]);

const store = useStore();

const isLoading = ref(true);

const animateModal = ref(false);

const basket = computed(() => store.state.order.cart);

const promocode = ref(null);

const activePromocode = ref(false);

onMounted(async () => {
  try {
    await Promise.all([store.dispatch("order/getCart")]);

    if (basket.value && basket.value.promocode && basket.value.promocode.code) {
      promocode.value = basket.value.promocode.code;
      activePromocode.value = true;
    } else {
      activePromocode.value = false;
    }

    isLoading.value = false;
  } catch (error) {
    console.log("Error loading data:", error);
  }
});

const deleteProduct = async (product) => {
  try {
    const response = await store.dispatch("order/deleteCart", {
      product_id: product.product_id,
    });

    if (response) {
      console.log(response);
    }
  } catch (err) {
    console.log(err);
  }
};

const reduceProduct = async (product) => {
  try {
    const response = await store.dispatch("order/reduceCart", {
      product_id: product.product_id,
    });

    if (response) {
      console.log(response);
    }
  } catch (err) {
    console.log(err);
  }
};

const pluseQuantity = async (product) => {
  try {
    const response = await store.dispatch("order/addCart", {
      product_id: product.product_id,
      quantity: 1,
    });

    if (response) {
      console.log(response);
    }
  } catch (err) {
    console.log(err);
  }
};

const usePromocode = async () => {
  await store
    .dispatch("order/usePromocode", {
      code: promocode.value,
    })
    .then((res) => {})
    .catch((err) => {});
};

const close = () => {
  animateModal.value = false;

  setTimeout(() => {
    emits("closeModal");
  }, 200);
};

const redirect = (event) => {
  router.push(event);
  close();
};

onMounted(() => {
  setTimeout(() => {
    animateModal.value = true;
  }, 80);
});
</script>

<style></style>
