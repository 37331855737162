<template>
  <nav class="navbar">
    <ul>
      <li
        v-for="(item, i) in navbar"
        :key="i"
        :class="{ active: route.path.includes(item.path) || route.path == item.pathName }"
        @click="push(item.path)"
      >
        <div class="icon" v-html="item.icon"></div>
        <span>
          {{ $t(item.title) }}
        </span>
      </li>
    </ul>
  </nav>
  <!-- <auth-modal
    v-if="storeAuthModal"
    @closeModal="()=> {
      store.dispatch('main/handleAuthModal', false)
    }"
  /> -->
  <!-- <basket-modal
    v-if="basketModal"
    @closeModal="basketModal = false"
  /> -->
</template>

<script setup>
import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import AuthModal from "@/components/partials/Modals/AuthModal.vue";
import BasketModal from "../partials/Modals/BasketModal.vue";

const route = useRoute();
const router = useRouter();

const store = useStore();

const storeAuthModal = computed(() => store.state.main.authModal);

const basketModal = ref(false);

const navbar = ref([
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M8.62602 14.5C9.07006 16.2252 10.6362 17.5 12.5 17.5C14.3638 17.5 15.9299 16.2252 16.374 14.5M11.5177 3.26401L4.73539 8.53914C4.28202 8.89176 4.05534 9.06807 3.89203 9.28887C3.74737 9.48446 3.6396 9.7048 3.57403 9.93907C3.5 10.2035 3.5 10.4907 3.5 11.0651V18.3C3.5 19.4201 3.5 19.9802 3.71799 20.408C3.90973 20.7843 4.21569 21.0903 4.59202 21.282C5.01984 21.5 5.57989 21.5 6.7 21.5H18.3C19.4201 21.5 19.9802 21.5 20.408 21.282C20.7843 21.0903 21.0903 20.7843 21.282 20.408C21.5 19.9802 21.5 19.4201 21.5 18.3V11.0651C21.5 10.4907 21.5 10.2035 21.426 9.93907C21.3604 9.7048 21.2526 9.48446 21.108 9.28887C20.9447 9.06807 20.718 8.89176 20.2646 8.53914L13.4823 3.26401C13.131 2.99076 12.9553 2.85413 12.7613 2.80162C12.5902 2.75528 12.4098 2.75528 12.2387 2.80162C12.0447 2.85413 11.869 2.99076 11.5177 3.26401Z" stroke="#667085" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    title: "main",
    pathName: "/",
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M18 18L14.375 14.375M16.3333 9.66667C16.3333 13.3486 13.3486 16.3333 9.66667 16.3333C5.98477 16.3333 3 13.3486 3 9.66667C3 5.98477 5.98477 3 9.66667 3C13.3486 3 16.3333 5.98477 16.3333 9.66667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    title: "catalog",
    path: "/catalog",
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4944 4.77985C8.82825 2.832 6.04987 2.30804 3.96233 4.09168C1.87478 5.87532 1.58089 8.85748 3.22025 10.967C4.58326 12.7209 8.70823 16.4201 10.0602 17.6174C10.2114 17.7513 10.287 17.8183 10.3753 17.8446C10.4523 17.8676 10.5365 17.8676 10.6135 17.8446C10.7017 17.8183 10.7773 17.7513 10.9286 17.6174C12.2805 16.4201 16.4055 12.7209 17.7685 10.967C19.4079 8.85748 19.1498 5.85656 17.0264 4.09168C14.903 2.3268 12.1605 2.832 10.4944 4.77985Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    title: "favorites",
    path: "/favorites",
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M5.1 2.70008L3.8 4.43341C3.54251 4.77673 3.41377 4.94839 3.41676 5.09207C3.41936 5.21711 3.47799 5.33437 3.57646 5.41148C3.68962 5.50008 3.90419 5.50008 4.33333 5.50008H16.6667C17.0958 5.50008 17.3104 5.50008 17.4235 5.41148C17.522 5.33437 17.5806 5.21711 17.5832 5.09207C17.5862 4.94839 17.4575 4.77673 17.2 4.43341L15.9 2.70008M5.1 2.70008C5.24667 2.50453 5.32 2.40675 5.41294 2.33623C5.49525 2.27377 5.58846 2.22716 5.68782 2.19879C5.8 2.16675 5.92222 2.16675 6.16667 2.16675H14.8333C15.0778 2.16675 15.2 2.16675 15.3122 2.19879C15.4115 2.22716 15.5047 2.27377 15.5871 2.33623C15.68 2.40675 15.7533 2.50453 15.9 2.70008M5.1 2.70008L3.53333 4.78897C3.33545 5.05281 3.23651 5.18474 3.16625 5.33002C3.1039 5.45893 3.05843 5.59534 3.03096 5.73588C3 5.89426 3 6.05916 3 6.38897L3 16.1667C3 17.1002 3 17.5669 3.18166 17.9234C3.34144 18.237 3.59641 18.492 3.91002 18.6518C4.26654 18.8334 4.73325 18.8334 5.66667 18.8334L15.3333 18.8334C16.2668 18.8334 16.7335 18.8334 17.09 18.6518C17.4036 18.492 17.6586 18.237 17.8183 17.9234C18 17.5669 18 17.1002 18 16.1667V6.38897C18 6.05916 18 5.89426 17.969 5.73588C17.9416 5.59534 17.8961 5.45893 17.8338 5.33002C17.7635 5.18474 17.6646 5.05282 17.4667 4.78897L15.9 2.70008M13.8333 8.83341C13.8333 9.71747 13.4821 10.5653 12.857 11.1904C12.2319 11.8156 11.3841 12.1667 10.5 12.1667C9.61594 12.1667 8.7681 11.8156 8.14298 11.1904C7.51786 10.5653 7.16667 9.71747 7.16667 8.83341" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    title: "basket",
    path: "/basket",
  },
  {
    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
    <path d="M4.93033 16.6988C5.43727 15.5044 6.62085 14.6667 8.00008 14.6667H13.0001C14.3793 14.6667 15.5629 15.5044 16.0698 16.6988M13.8334 8.41675C13.8334 10.2577 12.341 11.7501 10.5001 11.7501C8.65913 11.7501 7.16675 10.2577 7.16675 8.41675C7.16675 6.5758 8.65913 5.08341 10.5001 5.08341C12.341 5.08341 13.8334 6.5758 13.8334 8.41675ZM18.8334 10.5001C18.8334 15.1025 15.1025 18.8334 10.5001 18.8334C5.89771 18.8334 2.16675 15.1025 2.16675 10.5001C2.16675 5.89771 5.89771 2.16675 10.5001 2.16675C15.1025 2.16675 18.8334 5.89771 18.8334 10.5001Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    title: "profile",
    path: "/profile",
  },
]);

const toggleAuthModal = (event) => {
  if (store.getters["auth/isAuthenticated"]) {
    router.push("/profile");
  } else {
    // router.push('/auth')
    // authModal.value = !authModal.value;
    // basketModal.value = false;
  }
};

// const toggleBasketModal = (event) => {
//   basketModal.value = !basketModal.value;
//   authModal.value = false;
// };

const push = (path) => {
  if (path === "profile" || path === "basket") {
    if (store.getters["auth/isAuthenticated"]) {
      router.push(path);
      console.log(path, 'edwdew')
      store.dispatch('main/handleAuthModal', false);
    } else {
      store.dispatch('main/handleAuthModal', true);
    }
  }else if (path) {
    router.push(path);

    store.dispatch('main/handleAuthModal', false);
  } else {
    router.push('/');

    store.dispatch('main/handleAuthModal', false);
  }
};
</script>

<style></style>
